import { EBaseDomain } from '@shared/interfaces/base-domain.enum';
var BaseDomainResolver = /** @class */ (function () {
    function BaseDomainResolver() {
    }
    BaseDomainResolver.prototype.resolve = function (path) {
        if (path === void 0) { path = window.location.hostname; }
        switch (true) {
            case path.includes(EBaseDomain.SKYSMART):
                return EBaseDomain.SKYSMART;
            case path.includes(EBaseDomain.SKYENGSCHOOL):
                return EBaseDomain.SKYENGSCHOOL;
            default:
                return EBaseDomain.SKYENG;
        }
    };
    return BaseDomainResolver;
}());
export { BaseDomainResolver };
